<template>
	<div class="bindtelphone">
		<div>
			<div class="action">
				<el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">


					<el-form-item class="inputitem" prop="phone"  label="手机号">
						<el-input :readonly="true"  placeholder="请输入手机号码" v-model="ruleForm.phone"></el-input>
					</el-form-item>

					<el-form-item prop="code" class="inputitem" :rules="rules.code" label="验证码">
						<el-input placeholder="短信验证码" type="code" v-model="ruleForm.code">
							<i slot="suffix">
								<div class="inputslot">
									<!-- <div class='line'></div> -->
									<div class="msgBtn" v-if="timer === 0" @click.prevent="getMsgCode">获取验证码</div>
									<div class="msgBtn" v-else> {{ timer }}秒内输入</div>
								</div>
							</i>
						</el-input>
					</el-form-item>

					<el-form-item class="inputitem" prop="password" :rules="rules.password" label="密码">
						<el-input placeholder="密码" type="password" v-model="ruleForm.password"></el-input>
					</el-form-item>

					<el-form-item class="inputitem" prop="passworded" :rules="rules.passworded" label="确认密码">
						<el-input placeholder="确认密码" type="password" v-model="ruleForm.passworded"></el-input>
					</el-form-item>

					<el-form-item label=" " class="inputitem">
						<el-button type="primary" class="btnsubmit" @click="submitForm('ruleForm')">提 交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

	</div>
</template>

<script>
import loginHeader from "@/components/login/loginheader";
import { getTelphoneMsgCode } from "@/api/login";
import { changePassword,info } from "@/api/member";
export default {
	components: { loginHeader },


	computed: {

	

		membersWechatScanId: {
			get() {
				return this.$store.state.membersWechatScanId
			},
			set(v) {
				this.$store.commit('setMembersWechatScanId', v);
			}
		},
		membersId: {
			get() {
				return this.$store.state.membersId
			},
			set(v) {
				this.$store.commit('setMembersId', v);
			}
		},
	},



	data() {

		var validatePass2 = (rule, value, callback) => {
			// 这里每一种情况都要callback，不然表单验证会失效  
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}

		return {
			timer: 0,
			ruleForm: {
				phone:"",
				code: "",
				password: "",
				passworded: ""
			},

			rules: {
			

				phone: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					//验证手机号
					{
						pattern: /^1[3456789]\d{9}$/,
						message: "手机号码格式不正确",
						trigger: "blur",
					},
				],
				password: [
					{ required: true, min: 6, max: 18, message: "请输入6-18位密码", trigger: "blur" },

				],
				passworded: [
					{ validator: validatePass2, trigger: 'blur', required: true }
				],
				code: [
					{
						required: true,
						min: 4,
						max: 4,
						message: "验证码格式不正确",
						traggers: "blur",
					},
				],
			},
		};
	},
	mounted() { 
		this.getUserInfo()
	},


	methods: {

		getUserInfo() {
      info().then(res => {
        this.ruleForm.phone = res.data.phone
      })
    },

		getMsgCode() {
			//验证手机号码格式
			var that = this;
			this.$refs.ruleForm.validateField("phone", function (valid) {
			
				if (!valid) {
					const params = {
						phone: that.ruleForm.phone,
					};
					getTelphoneMsgCode(params).then((res) => {
						//发送成功后的处理
						that.timer = 120;
						const timerAction = setInterval(function () {
							if (that.timer > 0) {
								that.timer--;
							} else {
								clearInterval(timerAction);
							}
						}, 1000);
					});
				}
			});
		},

		changePCMode(v) {
			this.loginMode = v;
		},

		submitForm() {
			var that = this;
			this.$refs.ruleForm.validate(function (valid) {
				if (valid) {
					const params = {
						password: that.ruleForm.password,
						phone: that.ruleForm.phone,
						code: that.ruleForm.code,
					};
					changePassword(params).then((res) => {
						if (res.code === 1) {
							that.$alert(res.message, '修改密码', {
								confirmButtonText: '确定',
								type: 'success',
								callback: action => {
								}
							})
							that.ruleForm.password = ''
							that.ruleForm.passworded = ''
							that.ruleForm.code = ''
							that.getUserInfo()
						}

					});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.bindtelphone {
	width: 100%;
	min-height: calc(100vh - 180px);
	background: #fff;
}

.bindtitle {
	width: 80%;
	height: 50px;
	background: #ffffff;
	line-height: 50px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: left;
	padding-left: 20px;
	box-sizing: border-box;
}

.action {
	width: 100%;
	height: 232px;
	background: #ffffff;
	margin: 0 auto;
	padding-top: 10px;
	padding-left: 5px;
	box-sizing: border-box;

	.form {
		.inputitem {
			width: 420px;
			margin-top: 20px;
			text-align: left;
		}

		.btnsubmit {
			width: 120px;
		}
	}
}

.inputslot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 85px;

	.msgBtn {
		width: 80px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #28a7e1;
		cursor: pointer;
	}

	.line {
		height: 14px;
		width: 1px;
		background: #c5c5c5;
	}
}
</style>